<template>
  <div
    class="basic-data"
    v-loading="loading<4"
  >
    <div class="flex-container">
      <div class="left-side-panel">
        <h2 class="w-title-gradients fl">基础数据
          <el-tooltip
            class="item"
            effect="dark"
            content="当前店铺及其经营品牌的基础信息"
            placement="right"
          >
            <span>
              <i class="icon-help-tips"></i>
            </span>
          </el-tooltip>
        </h2>
        <i
          v-if="hasRightStore"
          class="icon-more-arrows fr"
          @click="goPage(0)"
        ></i>
        <div class="clearfix"></div>
        <ul class="data-list">
          <li>
            <span>店铺名称：</span>
            {{storeBasicData.storeName || '-'}}
          </li>
          <li><span>经营品牌：</span>{{storeBasicData.brandName || '-'}}</li>
          <li :title="storeBasicData.formatsLevel"><span>品牌业态：</span>{{storeBasicData.formatsLevel || '-'}}</li>
          <li><span>品牌分级：</span>{{storeBasicData.brandLevel || '-'}}</li>
          <li><span>品牌档次：</span>{{storeBasicData.brandGrade || '-'}}</li>
          <li :title="storeBasicData.costPerPersonMin+'-'+storeBasicData.costPerPersonMax+'元'">
            <span>人均消费：</span>
            {{storeBasicData.costPerPersonMin+'-'+storeBasicData.costPerPersonMax}}元
          </li>
        </ul>
        <h2 class="w-title-gradients fl">
          品牌标签
          <el-tooltip
            class="item"
            effect="dark"
            content="当前店铺经营品牌在云端品牌库的标签信息"
            placement="right"
          >
            <span>
              <i class="icon-help-tips"></i>
            </span>
          </el-tooltip>
        </h2>
        <i
          v-if="storeBasicData.brandId && !hasIdButNotset"
          class="icon-more-arrows fr"
          @click="goPage(1)"
        ></i>
        <div class="clearfix"></div>
        <ul
          class="label-list"
          v-if="storeBasicData.brandId && !hasIdButNotset"
        >
          <li v-if="details.industryTypeName !== ''">{{details.industryTypeName}}</li>
          <li
            v-for="(item, index) in tagNameList"
            :key="'tagName-'+index"
            v-show="tagNameList && tagNameList[0] != ''"
          >{{item}}</li>
          <li v-if="details.dicBrandGrade">{{details.dicBrandGrade}}</li>
          <li v-if="details.kaiDianMode">{{details.kaiDianMode}}</li>
          <li v-show="details.yearNum !== 0 && hasSetStoreData">
            <span>今年计划开店{{details.yearNum}}家</span>
          </li>
          <li
            class="style2"
            v-if="details.joinProjectNum !== 0 && hasSetStoreData"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span>已进驻{{details.joinProjectNum}}家购物中心{{details.chainMarketNum>0?'+' + details.chainMarketNum + '家百货':''}}</span>
              <div slot="content">基于1-4线城市3万平米及以上购物中心开店情况</div>
            </el-tooltip>
          </li>
          <li
            class="style2"
            v-if="details.chainMarketNum !== 0 && details.joinProjectNum === 0"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span>{{details.chainMarketNum>0?'已进驻' + details.chainMarketNum + '家百货':''}}</span>
              <div slot="content">基于1-4线城市3万平米及以上购物中心开店情况</div>
            </el-tooltip>
          </li>
          <template v-if="details && details.othertag">
            <li
              class="style1"
              v-for="(item, index) in otherTag"
              :key="'other' + index"
            >{{item}}
            </li>
          </template>
          <li
            class="style2"
            v-show="details.financing === 1"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="bottom"
            >
              <span>资本青睐品牌</span>
              <div slot="content">近两年已获机构资金投入，未来发展提速可能性大的品牌</div>
            </el-tooltip>
          </li>
          <li
            v-for="(item, index) in customLabel"
            :key='"cutom" + index'
            v-show="customLabel && customLabel[0] !== ''"
            class="style2"
          >{{item}}
          </li>
          <li
            v-for="(item, index) in operationFeature"
            :key="'operation' + index"
            v-show="operationFeature && operationFeature[0] !== ''"
            class="style2"
          >{{item}}
          </li>
          <li
            v-show="trendName && trendName[0] !== ''"
            v-for="(item, index) in trendName"
            :key="index"
            class="style2"
          >{{item}}
          </li>
          <li
            class="style2"
            v-if="details.newBranktop"
          >
            {{details.newBranktop}}
          </li>
        </ul>
        <div
          class="details-no-data mt-40 mb-40"
          v-if="(!storeBasicData.brandId && hasSetStoreData) || hasIdButNotset"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>云端品牌库未收录此品牌，暂无数据</p>
        </div>
        <br v-if="!hasSetStoreData">
        <h2 class="w-title-gradients fl">
          合同信息
          <el-tooltip
            class="item"
            effect="dark"
            content="当前店铺在本项目生效中的合同信息"
            placement="right"
          >
            <span>
              <i class="icon-help-tips"></i>
            </span>
          </el-tooltip>
        </h2>
        <i
          v-if="hasRightContract"
          class="icon-more-arrows fr"
          @click="goPage(2)"
        ></i>
        <div class="clearfix"></div>
        <ul class="data-list">
          <li><span>承租方：</span>{{storeBasicData.merchantName || '-'}}</li>
          <li>
            <span>主营业态：</span>
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-end"
              v-if="storeBasicData.diffPlanIndustry"
            >
              <div slot="content">
                <p>
                  规划：<span class="emphasis-text">{{storeBasicData.diffPlanIndustry}}</span>
                </p>
              </div>
              <span>
                <i
                  class="icon-warnning-tips"
                  style="margin-left:-8px"
                ></i>
              </span>
            </el-tooltip>
            {{storeBasicData.formats1Name || '-'}}
          </li>
          <li><span>租赁铺位：</span>{{storeBasicData.unitNumber || '-'}}</li>
          <li><span>经营方式：</span>{{storeBasicData.operationModeName || '-'}}</li>
          <li
            class="emphasis-item"
            :class="{'emphasis-text':storeBasicData.effectDateDescWarnDesc}"
          >
            <span>租赁期限：</span>
            <el-tooltip
              v-if="storeBasicData.effectDateDescWarnDesc"
              class="item"
              effect="dark"
              placement="top-end"
            >
              <div slot="content">
                <p>
                  剩余时长：<span class="emphasis-text">{{storeBasicData.expireDayNum | nonEmpty}}天</span>
                </p>
              </div>
              <span>
                <i
                  class="icon-warnning-tips"
                  style="margin-left:-8px"
                ></i>
              </span>
            </el-tooltip>
            {{storeBasicData.effectDateDesc || '-'}} 【{{storeBasicData.tenancy || '-'}}】
          </li>
        </ul>
        <h2 class="w-title-gradients fl">
          合同/租决对比
          <el-tooltip
            class="item"
            effect="dark"
            content="当前店铺本月的合同信息及铺位租决信息对比"
            placement="right"
          >
            <span>
              <i class="icon-help-tips"></i>
            </span>
          </el-tooltip>
        </h2>
        <i
          v-if="hasRightContrast && monthRentContractTabs === 1"
          class="icon-more-arrows fr"
          @click="goPage(2)"
        ></i>
        <i
          v-if="hasRentDecision && monthRentContractTabs === 0"
          class="icon-more-arrows fr"
          @click="goPage(3)"
        ></i>
        <div class="clearfix"></div>
        <ul class="c-tabs">
          <li
            :class="{'active-item': monthRentContractTabs===0}"
            @click="handleMonthRentContract(0)"
          >租决</li>
          <li
            :class="{'active-item': monthRentContractTabs===1}"
            @click="handleMonthRentContract(1)"
          >合同</li>
        </ul>
        <div
          class="tbs-wrap w-singed-table"
          v-loading="monthLoading"
          element-loading-spinner="el-icon-loading"
        >
          <table
            class="tbs-rent-contract"
            v-show="monthRentContractTabs===0"
            v-for="item in monthRentList"
            :key="item.id"
          >
            <tr>
              <td width="75">铺位</td>
              <td colspan="3">{{item.berthNo}}</td>
            </tr>
            <tr>
              <td>规划业态</td>
              <td>{{item.industry}}</td>
              <td>招商品牌级次</td>
              <td>{{item.brandLevel}}</td>
            </tr>
            <tr>
              <td>主次力店</td>
              <td>{{item.mainBerthType}}</td>
              <td>计租面积</td>
              <td>{{item.area}}㎡</td>
            </tr>
            <tr>
              <td>{{user.topuid === 6467 ? '物业' : '综合'}}管理费</td>
              <td>{{item.managementCost}}元</td>
              <td>租赁年限</td>
              <td>{{item.rentYear}}年</td>
            </tr>
            <template v-for="(ele, i) in item.berthTargets">
              <tr :key="'header'+i">
                <td
                  colspan="4"
                  class="cls-white"
                >{{ele.stepName}}</td>
              </tr>
              <tr :key="'tr1-'+i">
                <td>租金市场价</td>
                <td>{{ele.marketPrice + item.rentUnit}}</td>
                <td>租金标准价</td>
                <td>{{ele.rent + item.rentUnit}}</td>
              </tr>
              <tr :key="'tr2-'+i">
                <td>租金保底价</td>
                <td>{{ele.bottomPrice + item.rentUnit}}</td>
                <td>免租时长</td>
                <td>{{ele.freeLength}}天</td>
              </tr>
            </template>
          </table>
          <div
            class="details-no-data"
            v-if="monthRentList && monthRentList.length===0 && monthRentContractTabs===0 && isSetMonthRentList"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
          <table
            class="tbs-rent-contract"
            v-show="monthRentContractTabs===1"
            v-for="(item,index) in monthContractList"
            :key="index+item.contractId"
          >
            <tr>
              <td width="68">编号</td>
              <td>{{item.contractCode}}</td>
            </tr>
            <tr>
              <td>费用项目</td>
              <td>{{item.costItemName}}</td>
            </tr>
            <tr>
              <td>计算方式</td>
              <td>{{item.settleMethod}}</td>
            </tr>
            <tr>
              <td>结算周期</td>
              <td>{{item.settleCycle}}个月</td>
            </tr>
            <template v-for="(ele, index) in item.contractItems">
              <tr :key="'a'+index">
                <td>阶段期间</td>
                <td>{{(ele.stageDate || '')}}</td>
              </tr>
              <tr :key="'b'+index">
                <td>费用明细</td>
                <td v-html="ele.contractItemDetails.join('<br>')">{{}}</td>
              </tr>
            </template>
          </table>
          <div
            class="details-no-data"
            v-if="monthContractList && monthContractList.length===0 && monthRentContractTabs===1"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
        </div>
      </div>
      <div class="main-panel">
        <div class="panel-1">
          <div class="rent-data">
            <h2 class="w-title-gradients fl">
              租金数据
              <el-tooltip
                class="item"
                effect="dark"
                content="当前店铺在本项目的租金及费用情况"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h2>
            <i
              class="icon-more-arrows fr"
              @click="goPage(8)"
            ></i>
            <div class="clearfix"></div>
            <ul class="data-list">
              <li>
                累计租金(万元)
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  v-if="storeBasicData.rentMoneyWarnDesc"
                >
                  <div slot="content">
                    <p v-html="storeBasicData.rentMoneyWarnDescText"></p>
                  </div>
                  <span>
                    <i class="icon-warnning-tips"></i>
                  </span>
                </el-tooltip>
                <br>
                <span>{{storeBasicData.rentMoney | nonEmpty}}</span>
              </li>
              <li>
                平均月租金(万元/月)<br>
                <span>{{storeBasicData.avgRentMoney | nonEmpty}}</span>
              </li>
              <li>
                租金坪效(元/月/m²)<br>
                <span>{{storeBasicData.avgRentEffect | nonEmpty}}</span>
              </li>
              <li>
                费用收缴率
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  v-if="storeBasicData.captureRateWarnDesc"
                >
                  <div slot="content">
                    <p v-html="storeBasicData.captureRateWarnDescText"></p>
                  </div>
                  <span>
                    <i class="icon-warnning-tips"></i>
                  </span>
                </el-tooltip>
                <br>
                <span>{{storeBasicData.captureRate | nonEmpty}}%</span>
              </li>
              <li>
                当前欠费(万元)
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  v-if="storeBasicData.totalNoPayMoneyWarnDesc"
                >
                  <div slot="content">
                    <p v-html="storeBasicData.totalNoPayMoneyWarnDescText"></p>
                  </div>
                  <span>
                    <i class="icon-warnning-tips"></i>
                  </span>
                </el-tooltip>
                <br>
                <span>{{storeBasicData.totalNoPayMoney | nonEmpty}}</span>
              </li>
              <li>
                最长欠费账龄(天)
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  v-if="storeBasicData.oweDayNumWarnDesc"
                >
                  <div slot="content">
                    <p v-html="storeBasicData.oweDayNumWarnDescText"></p>
                  </div>
                  <span>
                    <i class="icon-warnning-tips"></i>
                  </span>
                </el-tooltip>
                <br>
                <span>{{storeBasicData.oweDayNum | nonEmpty}}</span>
              </li>
            </ul>
            <h3>近12个月租金及费用收缴统计</h3>
            <div
              id="chart-rent-data"
              class="chart-box"
            ></div>
          </div>
          <div class="sales-passenger-data">
            <h2 class="w-title-gradients fl">
              销售/客流数据
              <el-tooltip
                class="item"
                effect="dark"
                content="当前店铺在本项目的销售及客流情况"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h2>
            <i
              class="icon-more-arrows fr"
              @click="goPage(9)"
            ></i>
            <div class="clearfix"></div>
            <ul class="data-list">
              <li>
                累计销售额(万元)<br>
                <span>{{storeBasicData.salesAmount | nonEmpty}}</span>
              </li>
              <li>
                月均销售额(万元/月)<br>
                <span>{{storeBasicData.avgSalesAmount | nonEmpty}}</span>
              </li>
              <li>
                销售坪效(元/月/m²)
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  v-if="storeBasicData.avgSalesEffectWarnDesc"
                >
                  <div slot="content">
                    <p v-html="storeBasicData.avgSalesEffectWarnDescText"></p>
                  </div>
                  <span>
                    <i class="icon-warnning-tips"></i>
                  </span>
                </el-tooltip>
                <br>
                <span>{{storeBasicData.avgSalesEffect | nonEmpty}}</span>
              </li>
              <li>
                本月销售额(万元)
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  v-if="storeBasicData.currentMonthSalesAmountWarnDesc"
                >
                  <div slot="content">
                    <p v-html="storeBasicData.currentMonthSalesAmountWarnDescText"></p>
                  </div>
                  <span>
                    <i class="icon-warnning-tips"></i>
                  </span>
                </el-tooltip>
                <br>
                <span>{{storeBasicData.currentMonthSalesAmount | nonEmpty}}</span>
              </li>
              <li>
                本月客流量(千人)<br>
                <span>{{storeBasicData.passengerFlowNum | nonEmpty}}</span>
              </li>
              <li>
                月均租售比
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  v-if="storeBasicData.salesRentRatioWarnDesc"
                >
                  <div slot="content">
                    <p v-html="storeBasicData.salesRentRatioWarnDescText">
                    </p>
                  </div>
                  <span>
                    <i class="icon-warnning-tips"></i>
                  </span>
                </el-tooltip>
                <br>
                <span>{{storeBasicData.salesRentRatio | nonEmpty}}%</span>
              </li>
            </ul>
            <h3>近30日销售/客流统计 </h3>
            <div
              id="chart-sales-passenger"
              class="chart-box"
            ></div>
          </div>
        </div>
        <div class="panel-2">
          <div class="comprehensive-assessment">
            <h2 class="w-title-gradients fl">
              店铺综合评测
              <el-tooltip
                class="item"
                effect="dark"
                content="智能评测店铺经营情况，店铺经营指数越高，该店铺在项目中经营情况越好"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h2>
            <i
              class="icon-more-arrows fr"
              @click="goPage(7)"
            ></i>
            <div class="clearfix"></div>
            <div class="model-score">
              店铺经营指数：<span class="highlight">{{storeBasicData.storeScore | nonEmpty}}分 </span>
              高于<span class="percent">{{storeBasicData.scoreCompareRatio | nonEmpty}}%</span> 的同楼层同业态商户
            </div>
            <div
              id="store-assessment"
              class="chart-box"
              style="height:267px"
            ></div>
            <ul class="data-list fs-14">
              <li
                v-for="(item,index) in storeAssessmentList"
                :key="index"
              >
                {{item.label}}<br>
                <span>{{item.value | nonEmpty}}%</span>
              </li>
            </ul>
          </div>
          <div class="index-score">
            <h2 class="w-title-gradients fl">
              指数得分
              <el-tooltip
                class="item"
                effect="dark"
                placement="right"
              >
                <div slot="content">
                  <p>人气指数含义：反映品牌受消费者关注的程度。</p>
                  <p>综合实力指数含义：反映品牌综合实力。</p>
                  <p>租金贡献指数含义：反映品牌的租金贡献能力。</p>
                </div>
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h2>
            <i
              v-if="storeBasicData.brandId && !hasIdButNotset"
              class="icon-more-arrows fr"
              @click="goPage(5)"
            ></i>
            <div class="clearfix"></div>
            <ul
              class="index-list"
              v-if="storeBasicData.brandId && !hasIdButNotset"
            >
              <li
                v-for="(item,index) in indexList"
                :key="index"
              >
                <h3><i :class="'icon-index-score-'+index"></i>{{item.name}}</h3>
                <count-to
                  v-if="true"
                  class="num"
                  :start-val="0"
                  :end-val="item.score"
                  :duration="2600"
                  :decimals="1"
                />
                <p>高于 <span> {{item.percent}}% </span> 同类</p>
                <div class="index-line">
                  <span :style="'width:'+item.percent+'%;background:'+item.color"></span>
                </div>
              </li>
            </ul>
            <div
              class="details-no-data mt-20"
              v-if="(!storeBasicData.brandId && hasSetStoreData) || hasIdButNotset"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>云端品牌库未收录此品牌，暂无数据</p>
            </div>
          </div>
          <div class="tour-score">
            <h2 class="w-title-gradients fl">
              巡场评分
              <el-tooltip
                class="item"
                effect="dark"
                content="当前店铺在巡场检查中的评分情况"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h2>
            <i
              v-if="hasRightTour"
              class="icon-more-arrows fr"
              @click="goPage(6)"
            ></i>
            <div class="clearfix"></div>
            <div class="tbs-list">
              <h3>
                <span>巡场项目</span>
                <span>平均得分</span>
                <span>更新时间</span>
              </h3>
              <ul class="w-singed-table">
                <li
                  v-for="(item,index) in tourScoreList"
                  :key="index"
                >
                  <span>{{item.patrolItemName || '-'}}</span>
                  <span class="num">{{item.avgScore  || '-'}}</span>
                  <span>{{item.maxUpdateTime || '-'}}</span>
                </li>
                <li v-if="tourScoreList.length===0">
                  -
                </li>
                <br v-else>
              </ul>
            </div>
          </div>
        </div>
        <div class="brand-info">
          <h2 class="w-title-gradients fl">品牌资讯</h2>
          <i
            v-if="storeBasicData.brandId"
            class="icon-more-arrows fr"
            @click="goPage(4)"
          ></i>
          <div class="clearfix"></div>
          <ul v-if="brandInfo.length > 0">
            <li
              @click="toDetail(item)"
              v-for="item in brandInfo"
              :key="item.newsId"
            >
              <img
                v-if="item.firstPic"
                :src="item.firstPic"
                width="180"
                height="120"
                alt=""
              >
              <img
                v-else
                src="~@/assets/images/public/notPic.jpg"
                alt=""
              >
              <h3
                class="ellipsis"
                :title="item.title"
              >{{item.title}}</h3>
              <p class="ellipsis2 text">{{item.hexintishi}}</p>
              <p>
                <i class="icon-arrows-link pointer"></i>
                <span>
                  <i class="icon-clock"></i>
                  {{item.newsTime?item.newsTime.slice(0,10):'-'}}
                </span>
              </p>
            </li>
          </ul>
          <div
            class="details-no-data mt-20"
            v-if="(!storeBasicData.brandId || brandInfo.length===0) && hasSetStoreData"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>{{storeBasicData.brandId && !hasIdButNotset?'抱歉，暂无数据':'云端品牌库未收录此品牌，暂无数据'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import countTo from 'vue-count-to'
import { getCookie, powerAuth } from '@/utils'
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  name: 'basicData',
  data () {
    return {
      loading: 0,
      isFirstEnter: true,
      hasIdButNotset: false, // 接口有brandId返回，但在云端品牌库无此品牌
      storeBasicData: {
        storeName: '',
        brandName: '',
        formatsLevel: '',
        brandLevel: '',
        brandGrade: '',
        costPerPersonMin: '',
        costPerPersonMax: '',
        brandCode: ''
      },
      labelList: [],
      monthRentContractTabs: 0,
      monthLoading: false,
      monthRentList: [],
      isSetMonthRentList: false,
      monthContractList: {},
      chartRentData: null,
      chartSalesPassenger: null,
      chartStoreAssessment: null,
      storeAssessmentList: [],
      tourScoreList: [],
      indexList: [],
      brandInfo: [],
      details: [],
      tagNameList: [],
      customLabel: [],
      operationFeature: [],
      otherTag: [],
      hasSetStoreData: false,
      trendName: [],
      hasRightContract: true,
      hasRightTour: true,
      hasRightStore: true,
      hasRightContrast: true,
      hasRentDecision: false
    }
  },
  components: { countTo },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  mounted () {
    const userAuthoritiesContract = this.user.confIds.filter(item => {
      return item.id === 41
    })
    if (userAuthoritiesContract.length) {
      this.hasRightContract = true
    } else {
      this.hasRightContract = false
    }
    const userAuthoritiesTour = this.user.confIds.filter(item => {
      return item.id === 49
    })
    if (userAuthoritiesTour.length) {
      this.hasRightTour = true
    } else {
      this.hasRightTour = false
    }
    const userAuthoritiesContrast = this.user.confIds.filter(item => {
      return item.id === 49
    })
    if (userAuthoritiesContrast.length) {
      this.hasRightContrast = true
    } else {
      this.hasRightContrast = false
    }
    const userAuthoritiesStore = this.user.confIds.filter(item => {
      return item.id === 36
    })
    if (userAuthoritiesStore.length) {
      this.hasRightStore = true
    } else {
      this.hasRightStore = false
    }
    if (powerAuth(216)) {
      const data = {
        pageSize: 10,
        pageNum: 1,
        projectId: this.filtroProject
      }
      this.axios.post(api.berthTasList, data)
        .then((res) => {
          if (res.data.data.dataList.length > 0) {
            this.hasRentDecision = true
          } else {
            this.hasRentDecision = false
          }
        })
    }
    this.getDataBasicData()
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  filters: {
    nonEmpty (num) {
      let res = ''
      if (num === 0 || num) {
        res = num
      } else {
        res = '-'
      }
      return res
    }
  },
  watch: {
    filtroProject (newValue, oldValue) {
      console.log('filtroProject', this.filtroProject)
      if (!this.isFirstEnter) {
        this.getDataBasicData()
      }
    },
    filtroStore (newValue, oldValue) {
      console.log('filtroStore', this.filtroStore)
      this.getDataBasicData()
      this.isFirstEnter = false
    }
  },
  methods: {
    getDataBrandDetails () {
      if (this.storeBasicData.brandId) {
        const params = {
          brand_id: this.storeBasicData.brandId,
          gbid: this.storeBasicData.brandCode,
          source: 1
        }
        this.axios.post(api.getBrandExpandDemand, params).then(res => {
          if (res.data.code === 0) {
            this.hasIdButNotset = false
            this.details = null
            this.details = res.data.data
            this.hasRemark = this.details.hasRemark
            this.tagNameList = []
            if (res.data.data.tagname) {
              this.tagNameList = res.data.data.tagname.split(',')
            }
            this.trendName = []
            if (res.data.data.trendName) {
              this.trendName = res.data.data.trendName.split(',')
            }
            if (!this.details.brandLogo) {
              this.isLogo = true
            }
            // this.otherTag = res.data.data.othertag.split(',')
            this.otherTag = []
            if (res.data.data.othertag !== '') {
              // this.selectOther = res.data.othertag.split(',')
              const arr = []
              const otherArr = res.data.data.othertag.split(',')
              for (let i = 0; i < otherArr.length; i++) {
                if (otherArr[i].replace(/\s+/g, '') !== '') {
                  arr.push(otherArr[i])
                }
              }
              this.otherTag = arr
            }
            this.operationFeature = []
            if (res.data.data.operationFeature) {
              this.operationFeature = res.data.data.operationFeature.split(',')
            }
            // this.customLabel = res.data.data.customLabel ? res.data.data.customLabel.split(',') : []
            this.customLabel = []
            if (res.data.data.customLabel !== '') {
              const selfArr = res.data.data.customLabel.split(',')
              const customArr = []
              for (let i = 0; i < selfArr.length; i++) {
                if (selfArr[i].replace(/\s+/g, '') !== '') {
                  customArr.push(selfArr[i])
                }
              }
              this.customLabel = customArr
            }
          } else {
            this.details = {}
            this.hasIdButNotset = true
          }
        })
      }
    },
    toDetail (item) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent(item.title),
          newsId: item.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    goPage (key) {
      let path = null
      switch (key) {
        case 0: // 基础数据跳转店铺详情页，这里this.$gaodeDomain等合并5.3
          window.open(this.$gaodeDomain + '/omsViews/rent/assets/omsStore/details?menuCode=11&storeCode=' + this.filtroStore + '&mallCode=' + this.filtroProject + '&tokenval=' + getCookie('wisdom_token'), '_blank')
          break
        case 1: // 品牌标签跳转店铺详情页
          path = this.$router.resolve({
            path: '/brandDetail',
            query: {
              id: this.storeBasicData.brandId, // 等接口修改返回
              source: 1, // 跳品牌详情写死source:1、questionState:0，未明确原因的规则
              questionState: 0,
              gbid: this.storeBasicData.brandCode
            }
          })
          window.open(path.href, '_blank')
          break
        case 2: // 合同信息跳转合同详情页，这里this.$gaodeDomain等合并5.3，等后端
          window.open(this.$gaodeDomain + '/omsViews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + this.storeBasicData.contractCode + '&contractId=' + this.storeBasicData.contractId + '&mallCode=' + this.filtroProject + '&menuCode=12&operateStep=3&tokenval=' + getCookie('wisdom_token'), '_blank')
          break
        case 3: // 当月合同/租决对比跳转租决详情页，这里this.$gaodeDomain等合并5.3，等后端
          path = this.$router.resolve({
            path: '/leasingPlan'
          })
          window.open(path.href, '_blank')
          break
        case 4: // 品牌资讯跳转商业洞察页
          path = this.$router.resolve({
            path: '/brandTendencySearch',
            query: {
              search: this.storeBasicData.brandName
            }
          })
          window.open(path.href, '_blank')
          break
        case 5: // 指数得分跳转品牌详情
          path = this.$router.resolve({
            path: '/brandDetail',
            query: {
              id: this.storeBasicData.brandId,
              source: 1,
              questionState: 0,
              gbid: this.storeBasicData.brandCode
            }
          })
          window.open(path.href, '_blank')
          break
        case 6: // 指数得分跳转品牌详情
          window.open(this.$gaodeDomain + '/ossViews/patrol/patrolReport?menuCode=2&storeCode=' + this.filtroStore + '&mallCode=' + this.filtroProject + '&merchantCode=' + this.storeBasicData.merchantCode + '&tokenval=' + getCookie('wisdom_token'), '_blank')
          break
        case 7: // 商户综合评测跳转商户综合评测
          this.$router.push({
            path: '/earlywarningAnalysis/evaluationAnalysis',
            query: {
              storeCode: this.filtroStore
            }
          })
          break
        case 8: // 租金数据跳转租金关联分析
          this.$router.push({
            path: '/earlywarningAnalysis/rentAnalysis',
            query: {
              storeCode: this.filtroStore
            }
          })
          break
        case 9: // 销售/客流数据跳转销售关联分析
          this.$router.push({
            path: '/earlywarningAnalysis/salesAnalysis',
            query: {
              storeCode: this.filtroStore
            }
          })
          break
        default:
          break
      }
    },
    getDataIndexScore () {
      this.indexList = [
        {
          name: '人气指数',
          score: 0,
          percent: 0,
          color: '#FF754C'
        },
        {
          name: '租金贡献指数',
          score: 0,
          percent: 0,
          color: '#FFCE73'
        },
        {
          name: '综合实力指数',
          score: 0,
          percent: 0,
          color: '#3E8CFF'
        }
      ]
      const params = {
        gbid: this.storeBasicData.brandCode
      }
      this.axios.get(api.getIndexNums, { params: params }).then(res => {
        if (res.data.code === 0) {
          const colorList = ['#FF754C', '#FFCE73', '#3E8CFF']
          this.loading++
          const resData = res.data.data
          if (resData && resData.length) {
            this.indexList = resData.filter((item, index) => {
              item.color = colorList[index]
              return item
            })
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleText (str) {
      let html = ''
      if (str) {
        const list = str.split('：')
        html += list[0]
        html += '：<span class="emphasis-text">' + list[1] + '</span>'
      }
      return html
    },
    getDataBasicData () {
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.get(api.getStoreBaseData, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.loading++
          const resData = res.data.data
          // 累计租金
          resData.rentMoneyWarnDescText = ''
          if (resData.rentMoneyWarnDesc) {
            resData.rentMoneyWarnDescText = this.handleText(resData.rentMoneyWarnDesc)
          }
          // 费用收缴率
          resData.captureRateWarnDescText = ''
          if (resData.captureRateWarnDesc) {
            resData.captureRateWarnDescText = this.handleText(resData.captureRateWarnDesc)
          }
          // 当前欠费
          resData.totalNoPayMoneyWarnDescText = ''
          if (resData.totalNoPayMoneyWarnDesc) {
            resData.totalNoPayMoneyWarnDescText = this.handleText(resData.totalNoPayMoneyWarnDesc)
          }
          // 最长欠费账龄
          resData.oweDayNumWarnDescText = ''
          if (resData.oweDayNumWarnDesc) {
            resData.oweDayNumWarnDescText = this.handleText(resData.oweDayNumWarnDesc)
          }
          // 销售坪效
          resData.avgSalesEffectWarnDescText = ''
          if (resData.avgSalesEffectWarnDesc) {
            resData.avgSalesEffectWarnDescText = this.handleText(resData.avgSalesEffectWarnDesc)
          }
          // 本月销售额
          resData.currentMonthSalesAmountWarnDescText = ''
          if (resData.currentMonthSalesAmountWarnDesc) {
            for (let i = 0; i < resData.currentMonthSalesAmountWarnDesc.split('；').length; i++) {
              const element = resData.currentMonthSalesAmountWarnDesc.split('；')[i] + '<br>'
              resData.currentMonthSalesAmountWarnDescText += this.handleText(element)
            }
          }
          // 月均销售比
          resData.salesRentRatioWarnDescText = ''
          if (resData.salesRentRatioWarnDesc) {
            resData.salesRentRatioWarnDescText = this.handleText(resData.salesRentRatioWarnDesc)
          }
          this.storeBasicData = resData
          setTimeout(() => {
            this.hasSetStoreData = true
          }, 500)
          this.handleMonthRentContract(0)
          this.handleRentChart()
          this.handleSalesPassengerChart()
          this.handleStoreAssessmentChart()
          this.handleTourScore()
          this.handleBrandInfo()
          this.getDataIndexScore()
          this.getDataBrandDetails()
        }
      }, rej => {
        console.log('rej', rej)
      }).catch(err => {
        console.log('err', err)
      })
    },
    handleBrandInfo () {
      const params = {
        category: '',
        keyWord: this.storeBasicData.brandName,
        pageNum: 1,
        pageSize: 2
      }
      this.axios.post(api.brandNewsSearch, params).then(res => {
        if (res.data.code === 0) {
          this.loading++
          const resData = res.data.data
          this.brandInfo = resData.newsListVos.map(item => {
            return item
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleTourScore () {
      this.tourScoreList = this.storeBasicData.patrolRecordVoList.map(item => {
        return item
      })
    },
    handleStoreAssessmentChart () {
      const dataCurrent = [
        this.storeBasicData.yearRentEffectRatio,
        this.storeBasicData.yearCaptureRate,
        this.storeBasicData.yearTargetRentCompleteRatio,
        this.storeBasicData.yearPassengerFlowRatio,
        this.storeBasicData.yearSalesEffectRatio
      ]
      const dataOtherAverage = [
        this.storeBasicData.yearRentEffectRatioAvg,
        this.storeBasicData.yearCaptureRateAvg,
        this.storeBasicData.yearTargetRentCompleteRatioAvg,
        this.storeBasicData.yearPassengerFlowRatioAvg,
        this.storeBasicData.yearSalesEffectRatioAvg
      ]
      let maxItem = Math.max(...dataCurrent, ...dataOtherAverage)
      maxItem = (maxItem > 100 ? maxItem : 100)
      this.storeAssessmentList = [
        {
          label: '租金贡献度',
          value: this.storeBasicData.yearRentEffectRatio
        },
        {
          label: '费用收缴率',
          value: this.storeBasicData.yearCaptureRate
        },
        {
          label: '租决完成率',
          value: this.storeBasicData.yearTargetRentCompleteRatio
        },
        {
          label: '客流贡献度',
          value: this.storeBasicData.yearPassengerFlowRatio
        },
        {
          label: '销售贡献度',
          value: this.storeBasicData.yearSalesEffectRatio
        }
      ]
      this.chartStoreAssessment = echarts.init(document.getElementById('store-assessment'))
      window.addEventListener('resize', () => {
        this.chartStoreAssessment.resize()
      })
      const labelList = ['租金贡献度', '费用收缴率', '租决完成率', '客流贡献度', '销售贡献度']
      const _this = this
      this.chartStoreAssessment.setOption(
        {
          legend: {
            right: 10,
            icon: 'circle',
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              color: '#808191'
            }
          },
          tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            formatter: (params) => {
              let res = '<div style="padding:6px;line-height:24px">'
              res += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#3E8CFF;"></span>'
              res += '<span class="cls-common">' + _this.storeBasicData.brandName + '</span>'
              for (let i = 0; i < dataCurrent.length; i++) {
                const item = dataCurrent[i]
                res += '<br><span class="cls-low">' + labelList[i] + ': </span>' + item + '%'
              }
              res += '<p style="width: 100%;height: 1px;background: #373a43;margin:5px 0"></p>'
              res += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#A0D7E7;"></span>'
              res += '<span class="cls-common">' + _this.storeBasicData.floorName + '</span>'
              for (let i = 0; i < dataOtherAverage.length; i++) {
                const item = dataOtherAverage[i]
                res += '<br><span class="cls-low">' + labelList[i] + ': </span>' + item + '%'
              }
              res += '</div>'
              return res
            }
          },
          radar: {
            // shape: 'circle',
            axisLabel: {
              color: '#808191'
            },
            axisLine: {
              lineStyle: {
                color: '#808191'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#373A43'
              }
            },
            splitArea: {
              show: false
            },
            center: ['50%', '58%'],
            indicator: [
              { name: '租金贡献度', max: maxItem },
              { name: '费用收缴率', max: maxItem },
              { name: '租决完成率', max: maxItem },
              { name: '客流贡献度', max: maxItem },
              { name: '销售贡献度', max: maxItem }
            ]
          },
          color: ['#3E8CFF', '#A0D7E7'],
          series: [{
            name: '',
            type: 'radar',
            data: [
              {
                value: dataCurrent,
                areaStyle: {
                  color: 'rgba(160, 215, 231, 0.15)'
                },
                name: '当前'
              },
              {
                value: dataOtherAverage,
                areaStyle: {
                  color: 'rgba(62, 140, 255, 0.15)'
                },
                name: '同楼层同业态平均'
              }
            ]
          }]
        })
    },
    handleSalesPassengerChart () {
      const seriesSalesAmount = []
      const seriesPassengerFlowNum = []
      const xAxisData = this.storeBasicData.salesAmountPassengerFlowVoList.map(item => {
        seriesSalesAmount.push(item.salesAmount)
        seriesPassengerFlowNum.push(item.passengerFlowNum)
        return item.measureDate
      })
      this.chartSalesPassenger = echarts.init(document.getElementById('chart-sales-passenger'))
      window.addEventListener('resize', () => {
        this.chartSalesPassenger.resize()
      })
      this.chartSalesPassenger.setOption(this.chartsOptionBasic())
      this.chartSalesPassenger.setOption({
        tooltip: {
          formatter: (params) => {
            let res = '<div style="padding:6px;line-height:24px">'
            res += '<span class="cls-common">' + params[0].name + '</span>'
            for (let index = 0; index < params.length; index++) {
              const element = params[index]
              res += '<br><span class="cls-low">' + element.marker + element.seriesName + ': </span>' + element.data + (index === 0 ? '万元' : '千人')
            }
            res += '</div>'
            return res
          }
        },
        xAxis: {
          data: xAxisData
        },
        yAxis: [
          {
            name: '销售额/万元',
            offset: 0,
            interval: 40
          },
          {
            name: '客流量/千人',
            offset: 1
          }
        ],
        series: [
          {
            name: '销售额',
            data: seriesSalesAmount,
            type: 'line',
            smooth: true,
            barWidth: 16,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(63, 140, 255, .2)'
              }, {
                offset: 1,
                color: 'rgba(63, 140, 255, 0)'
              }])
            }
          },
          {
            name: '客流量',
            yAxisIndex: 1,
            data: seriesPassengerFlowNum,
            type: 'line',
            color: '#A0D7E7',
            smooth: true,
            barWidth: 16,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(160, 215, 231, .2)'
              }, {
                offset: 1,
                color: 'rgba(63, 140, 255, 0)'
              }])
            }
          }
        ]
      })
    },
    handleRentChart () {
      const seriesRentData = []
      const seriesCaptureRateData = []
      const xAxisData = this.storeBasicData.rentMoneyCaptureRateVoList.map(item => {
        seriesRentData.push(item.rentMoney)
        seriesCaptureRateData.push(item.captureRate)
        return item.measureMonth
      })
      this.chartRentData = echarts.init(document.getElementById('chart-rent-data'))
      window.addEventListener('resize', () => {
        this.chartRentData.resize()
      })
      this.chartRentData.setOption(this.chartsOptionBasic())
      this.chartRentData.setOption({
        tooltip: {
          formatter: (params) => {
            let res = '<div style="padding:6px;line-height:24px">'
            res += '<span class="cls-common">' + params[0].name + '</span>'
            for (let index = 0; index < params.length; index++) {
              const element = params[index]
              res += '<br><span class="cls-low">' + element.marker + element.seriesName + ': </span>' + element.data + (index === 0 ? '万元' : '%')
            }
            res += '</div>'
            return res
          }
        },
        xAxis: {
          data: xAxisData
        },
        yAxis: [
          {
            name: '租金/万元',
            offset: 0,
            interval: 40
          },
          {
            name: '费用收缴率',
            offset: 1,
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: '租金',
            data: seriesRentData,
            type: 'bar',
            barWidth: 16
          },
          {
            name: '费用收缴率',
            yAxisIndex: 1,
            data: seriesCaptureRateData,
            type: 'line',
            smooth: true,
            barWidth: 16
          }
        ]
      })
    },
    chartsOptionBasic () {
      const option = {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          bottom: 5,
          textStyle: {
            color: '#808191'
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 32,
          icon: 'circle'
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#808191'
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#808191'
            },
            splitLine: {
              lineStyle: {
                color: '#373A43',
                type: 'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#373A43'
              }
            }
          },
          {
            type: 'value',
            nameTextStyle: {
              color: '#808191'
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#808191'
            },
            splitLine: {
              lineStyle: {
                color: '#373A43',
                type: 'dashed'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#373A43'
              }
            }
          }
        ],
        grid: {
          left: 32,
          right: 41,
          top: 30,
          bottom: 50
        },
        color: ['#3E8CFF', '#A0D7E7']
      }
      return option
    },
    handleMonthRentContract (key) {
      this.monthLoading = true
      this.monthRentContractTabs = key
      if (key) {
        const params = {
          contractCode: this.storeBasicData.contractCode
        }
        this.axios.get(api.getContractCostInfo, { params: params }).then(res => {
          if (res.data.code === 0) {
            this.monthLoading = false
            this.loading++
            const resData = res.data.data
            this.monthContractList = resData.map(item => {
              return item
            })
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      } else {
        const params = {
          storeCode: this.filtroStore
        }
        this.axios.get(api.getBerthTarget, { params: params }).then(res => {
          if (res.data.code === 0) {
            this.monthRentList = []
            this.monthLoading = false
            this.loading++
            const resData = res.data.data
            this.monthRentList = resData.map(item => {
              return item
            })
            this.isSetMonthRentList = true
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.basic-data
  .flex-container
    display flex
    min-height 1078px
    margin-right 23px
    justify-content space-between
    .chart-box
      height 219px
      margin 0 28px
    #store-assessment
      margin 0 3px !important
    .left-side-panel, .rent-data, .sales-passenger-data, .comprehensive-assessment, .index-score, .tour-score, .brand-info
      background #272930
    .left-side-panel
      margin-right 14px
      flex 0 0 420px
      .data-list
        margin 8px 16px 40px 16px
        li
          display inline-block
          font-family PingFang SC
          width 172px
          font-size 14px
          line-height 18px
          overflow hidden
          white-space nowrap
          text-overflow ellipsis
          margin-top 16px
          color #808191
          color #fff
          &:nth-child(2n-1)
            margin-right 44px
          &.emphasis-item
            width 388px
          span
            color #808191
      .label-list
        margin 24px 16px 24px 16px
        font-size 12px
        li
          display inline-block
          margin 0 16px 16px 0
          line-height 22px
          padding 0 11px
          background #3D3F45
          border-radius 11px
          &.emphasis-item
            background rgba(199, 114, 86, 0.15)
            color #D6795A
        li.style1
          background rgba(222, 194, 167, 0.1)
          color #FFC690
        li.style2
          background rgba(255, 88, 36, 0.1)
          color #FE8F69
      .c-tabs
        padding 3px
        border 1px solid #373A43
        border-radius 2px
        color #808191
        margin 24px auto 17px auto
        width 122px
        li
          width 61px
          height 26px
          line-height 26px
          font-size 12px
          border-radius 0 2px 2px 0
          display inline-block
          text-align center
          cursor pointer
          transition background 0.1s ease
          &:first-child
            border-radius 2px 0 0 2px
          &.active-item
            background #FFA134
            color #fff
      .tbs-wrap
        margin 0 17px
        height 381px
        overflow-y auto
        .tbs-rent-contract
          margin-bottom 17px
          font-size 14px
          border-top 1px solid #373A43
          border-left 1px solid #373A43
          width 98%
          color #808191
          td
            padding 12px
            border-bottom 1px solid #373A43
            border-right 1px solid #373A43
          td:nth-child(2n-1)
            color #B2B3BD
          td:nth-child(2n)
            color #fff
          td.cls-white
            color #fff !important
    .main-panel
      display flex
      flex 1 1 1255px
      flex-flow row wrap
      .rent-data, .comprehensive-assessment, .sales-passenger-data
        height 426px
        margin-bottom 14px
      .tour-score, .index-score
        height 206px
        margin-bottom 14px
      .tour-score
        .w-singed-table
          height 171px
        .tbs-list
          width 96%
          margin 12px auto
          fonts-size 14px
          color #808191
          h3
            color #B2B3BD
            padding-bottom 15px
            border-bottom 1px solid #373a43
            font-size 14px
          span
            display inline-block
            width 33%
          ul
            height 121px
            overflow-y auto
            li
              color #808191
              text-align left
              font-size 14px
              line-height 38px
              border-bottom 1px solid #373A43
              position relative
              span.num
                color #F89407
              &:last-child
                border none
              &:nth-child()
                border none
      .panel-1
        flex 1 1 54.9%
        margin-right 14px
      .rent-data, .sales-passenger-data
        ul.data-list
          display flex
          flex-flow row wrap
          justify-content space-between
          margin 12px 0 0 28px
          li
            flex-basis 33.3%
            color #808191
            font-size 14px
            span
              font-size 24px
              color #fff
            &:first-child, &:nth-child(2), &:nth-child(3)
              margin-bottom 28px
        h3
          color #B2B3BD
          text-align center
          margin-top 11px
          font-size 14px
      .panel-2
        flex 1 1 43.8%
        .comprehensive-assessment
          .model-score
            font-size 14px
            margin 8px 16px
            background #373A43
            height 51px
            line-height 51px
            color #808191
            text-indent 16px
            span.highlight
              font-size 26px
              color #fff
              margin-right 7px
            span.percent
              color #B2B3BD
          .data-list
            li
              width 20%
              display inline-block
              color #808191
              position relative
              text-align center
              span
                color #fff
                font-size 20px
              &:after
                content ''
                width 1px
                height 17px
                background #373A43
                position absolute
                right 0
                top 30%
              &:last-child:after
                display none
        .index-score
          .index-list
            font-size 14px
            margin 11px 0
            color #808191
            li
              display inline-block
              width 28%
              margin-left 5%
              border-right 1px solid #373A43
              i
                margin-right 5px
                position relative
                width 16px
                height 16px
                display inline-block
              .icon-index-score-0
                top 2px
                background url('~@/assets/images/storeEarlywarning/icon-score-0.png')
              .icon-index-score-2
                top 2px
                background url('~@/assets/images/storeEarlywarning/icon-score-2.png')
              &:last-child
                border none
              h3
                color #fff
                margin 12px 0 20px 0
              span.num
                font-size 36px
                color #fff
              p
                span
                  color #B2B3BD
              .index-line
                width 90%
                height 5px
                margin-top 13px
                background #373A43
                border-radius 5px
                position relative
                span
                  content ''
                  display inline-block
                  width 0
                  height 5px
                  border-radius 5px
                  position absolute
                  transition width ease-in-out 1s
      .brand-info
        flex-basis 1255px
        height 198px
        ul
          margin 26px auto 0 auto
          width 98%
          li
            width 47%
            float left
            img
              float left
              margin-right 12px
              border-radius 2px
            h3
              font-size 16px
              color #fff
              font-weight 700
            p
              font-size 14px
              color #808191
              a
                float left
              span
                float right
            h3, p
              overflow hidden
              margin 4px 0 12px 0
              position relative
              top 0
              transition top ease-in-out 0.2s
            &:hover
              cursor pointer
              h3
                color #F89407
              p, .icon-clock:before
                color #B2B3BD
              h3, p
                top -10px
              .icon-arrows-link:before, h3
                color #f89407
          li:nth-child(2)
            float right
  .cls-white
    color #fff
  .icon-help-tips
    position relative
    top 1px
  .icon-more-arrows
    margin 6px 12px 0 0
    cursor pointer
    padding 0 5px
    &:hover:before
      color #f89407
  .icon-warnning-tips:before
    font-size 16px
    position relative
    top 2px
    margin 0 0 0 4px
  .item
    font-size 12px !important
  .fs-14
    font-size 14px
  .mt-20
    margin-top 20px
  .mt-40
    margin-top 40px
  .mb-40
    margin-bottom 40px
  .icon-arrows-link:hover
    &:before
      color #f89407
.basic-data>>>
  .el-loading-mask
    background rgba(0, 0, 0, 0.3) !important
@media screen and (max-width 1825px)
  // .basic-data .flex-container .main-panel .panel-1
  // margin-right 0
  .basic-data .flex-container .left-side-panel .tbs-wrap
    height 1260px
</style>
